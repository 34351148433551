<template>
  <n-message-provider>
    <div class="container-fluid p-0">
      <Header />
      <router-view />
      <Footer v-if="false" />
      <DownloadingAppFooter v-if="false" />
    </div>
  </n-message-provider>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import DownloadingAppFooter from "./components/DownloadingAppFooter.vue";
const { Commons } = require("./assets/js/commons");
const { AnalyticsUtils } = require("./assets/js/analyticsUtils");
export default {
  name: "App",
  components: {
    Header,
    Footer,
    DownloadingAppFooter,
  },
  mounted() {
    Commons.initGlobalVariables();
    const isFirstVisit = Commons.isEmpty(
      this.$cookies.get("smallkash.isFirstVisit")
    );
    AnalyticsUtils.initAnalytics(isFirstVisit);
    this.$cookies.set("smallkash.isFirstVisit", true);
    document.addEventListener("click", function (event) {
      let ctaTag;
      let ctaLinkClicked = false;
      event.path.map((element) => {
        if (/cta-link/i.test(element.className)) {
          ctaTag = element;
          ctaLinkClicked = true;
        }
      });
      if (ctaLinkClicked) {
        AnalyticsUtils.catchEvent("cta link clicked", {
          linkText: ctaTag.innerText,
          linkUrl: ctaTag.href,
        });
      }
    });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;900&display=swap");
@font-face {
  font-family: "circular-bold";
  src: url("./assets/font/circular_std_bold.otf");
  font-display: swap !important;
}

@font-face {
  font-family: "circular-medium";
  src: url("./assets/font/circular_std_medium.otf");
  font-display: swap !important;
}

.harlow {
  font-family: "myFirstFont";
}

:root {
  --color1: #395174;
  --color2: #627796;
  --color3: #1030ff;
  --color4: #481985;
  --color5: #0b3641;
  --white1: white;
  --white2: #faf8fb;
  --white3: #d1eff7;
  --white4: #abb7bb;
  --white5: #f0f4fd;
  --white6: #e9f7fb;
}

html,
body {
  min-height: max-content;
  font-family: "circular-medium" "Work Sans" !important;
  font-display: swap !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  background-color: var(--white2) !important;
  overflow-x: hidden;
}

* {
  color: var(--color2) !important;
}

.blackText {
  color: black !important;
}

h1.bold {
  letter-spacing: 0.9px;
}

.bold {
  font-weight: 900 !important;
  font-family: "Work Sans" !important;
}

.bold2 {
  font-family: "circular-bold" !important;
}

.color1 {
  color: var(--color1) !important;
}
.color2 {
  color: var(--color2) !important;
}
.color3 {
  color: var(--color3) !important;
}
.color4 {
  color: var(--color4) !important;
}

.ctaBtn {
  color: white !important;
  background-color: var(--color3) !important;
}
.ctaBtn:hover {
  color: white !important;
  background-color: var(--color3) !important;
}

.customedBtn {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.65em !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  padding-right: 2rem;
  padding-left: 2rem;
  font-weight: 900 !important;
  font-family: "circular-bold" "Work Sans" !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.noShadow {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.bg-white3 {
  background-color: var(--white3) !important;
}

.bg-white5 {
  background-color: var(--white5) !important;
}

.rightArrowSvg {
  mask: url("./assets/pictures/arrow-right.svg") no-repeat center;
  background-size: contain;
  mask-size: 20px 20px;
  width: 20px;
  height: 20px;
  background-color: var(--color3);
}

.svgWhite {
  background-color: white !important;
}

.cursorPointer,
.cursorPointer > *,
.zoomHover,
.zoomHover > * {
  cursor: pointer !important;
}
.zoomHover:hover {
  transform: scale(1.04);
}
a,
a:hover {
  color: var(--color1) !important;
}
.cardSession {
  box-shadow: var(--white4) -6px 14px 20px 1px;
  border-radius: 0.7em;
}

img {
  background-image: linear-gradient(
    to right,
    #ebebeb calc(50% - 100px),
    #c5c5c5 50%,
    #ebebeb calc(50% + 100px)
  );
  background-size: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}

img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
}

@keyframes move {
  to {
    transform: translateX(calc(50% + 100px));
  }
}
.animate__animated {
  animation-duration: 1.8s !important;
}

.floatAnim {
  animation: float 3s infinite;
}

.floatAnimNoBg {
  animation: floatNoBg 3s infinite;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@keyframes floatNoBg {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
</style>
