<template>
  <nav class="navbar navbar-light navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="navbar-brand logo zoomHover">
        <img src="../../public/logo.png" /> Chipdeals Marchand
      </router-link>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0"></ul>
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0 d-auto d-md-inline-block">
          <li v-if="false"><LangChanger /></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from "vue";
import LangChanger from "@/components/LangChanger.vue";

export default defineComponent({
  name: "Header",
  components: { LangChanger },
  data() {
    return {
      featuresList: [
        {
          label: this.$t("header.featuresList.item1"),
          key: "#",
        },
      ],
      helpsList: [
        {
          label: this.$t("header.helpsList.item1"),
          key: "#",
        },
      ],
      companyList: [
        {
          label: this.$t("header.companyList.item1"),
          key: "#",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    handleSelect(path) {
      this.$router.push(path);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a,
a:hover {
  text-decoration: none;
}
.logo {
  color: var(--color1) !important;
  max-width: 50px;
  max-height: 100%;
  min-width: 50px;
  display: inline-block;
  height: auto;
  object-fit: contain;
}

.logo > img {
  width: 35px !important;
}

.navBar {
  background-color: transparent !important;
}
</style>
