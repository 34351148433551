<template>
  <section class="py-5">
    <div class="container text-center">
      <h1 class="bold color1">Opérateurs supportés</h1>
      <div
        class="
          m-0
          row
          justify-content-around
          g-5
          row-cols-2 row-cols-sm-3 row-cols-md-5
        "
      >
        <div
          class="partnerPicContainer"
          v-for="(partnerPic, partnerPicKey) in operatorsPics"
          :key="partnerPicKey"
        >
          <img
            class="mx-auto partnerPic"
            :src="require(`@/assets/pictures/${partnerPic.pictureName}`)"
            :alt="partnerPic.name"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "OperatorsSession",
  components: {},
  data() {
    return {
      operatorsPics: [
        { pictureName: "mtnLogo.png", name: "Mobile Money" },
        { pictureName: "moovLogo.png", name: "Moov Money" },
      ],
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.partnerPicContainer {
  height: 100px;
}
.partnerPic {
  max-height: 100%;
  width: auto !important;
  max-width: 100%;
}
</style>
