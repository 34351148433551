const { RequestUtils } = require("./requestUtils");
const { Commons } = require("./commons");

class Utils {
  static async getTotalUserEconomyAmount() {
    const userEconomyResponse = await RequestUtils.request(
      "https://apis.chipdeals.me/smallkash/userseconomies"
    );
    if (Commons.isEmpty(userEconomyResponse.totalUsersEconomies)) return 0;
    return userEconomyResponse.totalUsersEconomies;
  }
}

exports.Utils = Utils;
