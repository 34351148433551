<template>
  <PictureSession
    componentClass="mb-5"
    textClass="col-12 col-sm-12 col-md-6"
    pictureClass="col-12 col-sm-12 col-md-6 "
    picturAndTextContainerClass="animate__animated animate__fadeIn "
    manualChangeTextCol
    manualChangePictureCol
  >
    <template v-slot:textPart>
      <div class="my-auto">
        <h1 class="bold color1 welcomeTitle">
          Fais toi facilement
          <span class="specialHeaderUnderline color1">payer à distance</span>
          pour tes produits
        </h1>
        <span class="smallSize d-inline-block mt-2">
          Facilite le paiement pour tes clients lorsque tu acceptes une commande
        </span>
        <p class="pt-3 d-md-block d-none">
          <svg class="checkedBox" />
          <span class="bold"> Télécharge </span> Chipdeals Marchand <br />
          <svg class="checkedBox" />
          <span class="bold"> Connecte </span> ton
          <span class="bold"> MoMoPay </span>
          <br />
          <svg class="checkedBox" />
          <span class="bold"> Génere des liens </span> avec l'App pour te faire
          payer
          <span class="bold"> pour te faire payer </span>

          <br />
        </p>
        <p class="text-center text-md-start pt-4 w-75 mx-auto">
          <a
            @click="simulateConversationMessageClick()"
            href="#"
            style="font-size: 0.7em;"
            class="
              d-md-inline-block- d-none-
              customedBtn
              ctaBtn
              whiteBtn
              zoomHover
              
            "
          >
            <span class="d-none- d-lg-inline- text-white">
              Nous écrire &
            </span>
            Intégrer
            <svg class="rightArrowSvg svgWhite" />
          </a>
          <a
            v-if="false"
            id="downloadFromGooglePlay"
            href="#"
            class="
              d-md-none
              googleDownload
              noShadow
              customedBtn
              zoomHover
              p-0
              
            "
          >
            <img src="@/assets/pictures/google-play-fr-telecharge.png" />
          </a>
          <br />
          <span class="smallSize d-none d-md-inline">
            *
            <n-tooltip
              trigger="hover"
              placement="bottom"
              :style="{
                'background-color': 'var(--white6)',
                color: 'var(--color5)!important',
              }"
            >
              <template #trigger>
                <span class="underlineDotted cursorPointer">Tous réseaux</span>
              </template>
              MTN & MOOV
            </n-tooltip>

            supportés & ton argent est
            <n-tooltip
              trigger="hover"
              placement="bottom-left"
              :style="{
                'background-color': 'var(--white6)',
                color: 'var(--color5)!important',
              }"
            >
              <template #trigger>
                <span class="underlineDotted cursorPointer">sécurisé </span>
              </template>
              Sécurité de niveau militaire. Nous ne te demanderons
              <span class="bold"> jamais </span> ton code secret
            </n-tooltip>
          </span>
          <span class="smallSize d-md-none d-inline">
            *
            <n-tooltip
              trigger="click"
              placement="bottom"
              :style="{
                'background-color': 'var(--white6)',
                color: 'var(--color5)!important',
              }"
            >
              <template #trigger>
                <span class="underlineDotted cursorPointer">Tous réseaux</span>
              </template>
              MTN & MOOV
            </n-tooltip>

            supportés & ton argent est
            <n-tooltip
              trigger="click"
              placement="bottom-end"
              :style="{
                width: '250px!important',
                'background-color': 'var(--white6)',
                color: 'var(--color5)!important',
              }"
            >
              <template #trigger>
                <span class="underlineDotted cursorPointer">sécurisé </span>
              </template>
              Sécurité de niveau militaire. Nous ne te demanderons
              <span
                class="bold"
                :style="{
                  color: 'var(--color5)!important',
                }"
              >
                jamais ton code secret !
              </span>
            </n-tooltip>
          </span>
        </p>
      </div>
    </template>
    <template v-slot:picturePart>
      <WellcomeSessionPicture />
    </template>
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
import WellcomeSessionPicture from "@/components/WellcomeSessionPicture.vue";
export default defineComponent({
  name: "WellcomeSession",
  components: { PictureSession, WellcomeSessionPicture },
  data() {
    return {};
  },
  mounted() {
    // var observer = new IntersectionObserver(
    //   function (entries) {
    //     if (entries[0].isIntersecting === true) {
    //       window.smallKash.downloadFromGoogleIsVisible = true;
    //     } else {
    //       window.smallKash.downloadFromGoogleIsVisible = false;
    //     }
    //     console.log(window.smallKash.downloadFromGoogleIsVisible);
    //   },
    //   { threshold: [1] }
    // );

    // observer.observe(document.querySelector("#downloadFromGooglePlay"));
  },
  methods: {
    simulateConversationMessageClick(){
      window.simulateConversationMessageClick()
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.welcomeTitle {
  font-size: 1.63em !important;
}
.specialHeaderUnderline {
  background: linear-gradient(89.82deg, #e6e9ff 4.65%, #7482e9 113.85%), #7482e9;
  background-size: 100% 12px;
  background-repeat: no-repeat;
  padding: 0 2 px;
  background-position: bottom;
  background-color: transparent;
}

.text-small {
  font-size: 0.8em;
}
.checkedBox {
  background-color: green;
  mask: url("../assets/pictures/check.svg") no-repeat center;
  background-size: contain;
  mask-size: 20px 20px;
  width: 20px;
  height: 20px;
}

.underlineDotted {
  text-decoration: underline dashed var(--color3);
  color: var(--color3) !important;
}

.infoSvg {
  background-color: var(--color3);
  mask: url("../assets/pictures/info-button.svg") no-repeat center;
  background-size: contain;
  mask-size: 17px 17px;
  width: 17px;
  height: 20px;
  margin-bottom: 3px;
}

.smallSize {
  font-size: 0.71em;
}

.mockupContainerContainer {
  height: 30em;
  width: 15em;
  transform: translateX(-50%);
  left: 50%;
}

.mockupContainer {
  height: 100%;
  position: relative;
  width: 100%;
  transform: rotate(0.7deg);
}
.mockupPictureContainer {
  position: absolute;
}
.forgroundTextContainer {
  position: absolute;
  top: 120px;
  transform: scale(1.4) rotate(359.7deg);
  width: max-content;
  border-radius: 0.6em;
  box-shadow: var(--white4) -1px 4px 12px 2px;
  font-size: 0.9em;
}
.googleDownload {
  width: 45%;
}

.operators {
  position: absolute;
  height: 30em;
  width: 30em;
  transform: translateX(-50%);
  left: 50%;
}

.operatorPicContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.1px rgba(0, 0, 0, 0.1) dotted;
  text-align: center;
  border-radius: 100%;
  animation: orbit-anim 10s linear infinite;
}

.operatorPic {
  position: relative;
  top: -35px;
  height: 70px;
  width: auto;
  animation: orbit-anim-inverted 10s linear infinite;
}

.smallRing {
  width: 80%;
  height: 80%;
  margin: 10%;
}

.inverseSens {
  animation: orbit-anim-inverted 10s linear infinite;
}
.inverseSens > .operatorPic {
  animation: orbit-anim 10s linear infinite;
}
.delay,
.delay > .operatorPic {
  animation-delay: 2.5s;
}

@-webkit-keyframes orbit-anim {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes orbit-anim-inverted {
  100% {
    transform: rotate(-360deg);
  }
}

@media screen and (max-width: 546px) {
  .mockupContainerContainer {
    height: 16em;
    width: 10em;
  }

  .forgroundTextContainer {
    top: 76px;
    left: -45px;
    transform: scale(0.9) rotate(359.7deg);
  }

  .operators {
    height: 17em;
    width: 17em;
  }

  .operatorPic {
    top: -20px;
    height: 40px;
  }
}
</style>
<style>
.n-popover-arrow {
  background-color: var(--white6) !important;
}
</style>
