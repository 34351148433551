export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "getSmallkash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharge SmallKash"])},
  "header": {
    "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrprise"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se Connecter"])},
    "featuresList": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item1"])}
    },
    "helpsList": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item1"])}
    },
    "companyList": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item1"])}
    }
  }
}