<template>
  <section class="py-5" :class="componentClass">
    <div class="container">
      <div
        class="row justify-content-around"
        :class="picturAndTextContainerClass"
      >
        <div
          class="d-flex pb-4 textSession"
          :class="[
            textClass,
            { 'order-sm-last': textToRight },
            { 'text-center text-sm-start': !manualChangeTextPosition },
            { 'col-sm-6 col-lg-5 ': !manualChangeTextCol },
          ]"
        >
          <slot name="textPart"> textHere </slot>
        </div>
        <div
          class="d-inline-flex text-center"
          :class="[pictureClass, { 'col-sm-5 ': !manualChangePictureCol }]"
        >
          <slot name="picturePart">
            <lottie-player
              class="animate__animated animate__zoomIn mx-auto"
              src="https://assets2.lottiefiles.com/packages/lf20_ocib98vu.json"
              background="transparent"
              speed="1.5"
              style="width: 100%"
              loop
              autoplay
            ></lottie-player>
          </slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PictureSession",
  props: {
    componentClass: String,
    pictureClass: String,
    textClass: String,
    picturAndTextContainerClass: String,
    textToRight: Boolean,
    manualChangeTextPosition: Boolean,
    manualChangeTextCol: Boolean,
    manualChangePictureCol: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textSession {
  z-index: 3;
}
</style>
