class Commons {
  static isEmpty(data) {
    if (/number/.test(typeof data)) {
      return false;
    }
    if (!data) {
      return true;
    }
    if (Array.isArray(data)) {
      return data.length === 0;
    }
    const dataProperties = Object.values(data);
    return dataProperties && dataProperties.length == 0;
  }
  static serverBaseUrl() {
    if (process.env.NODE_ENV === "prod") {
      return liveServerUrl;
    }
    return devServerUrl;
  }
  static initGlobalVariables(){
    window.smallKash={}
    window.smallKash.downloadFromGoogleIsVisible = false
  }

  static isLiveEnv() {
    return process.env.NODE_ENV !== "development";
  }
}

const liveServerUrl = "https://apis.chipdeals.me/smallkash";
const devServerUrl = "https://localhost:3005";

exports.Commons = Commons;
