<template>
  <div class="slideContaner py-5 mt-4">
    <input
      @mouseup="updateValue(value)"
      @touchend="updateValue(value)"
      class="rangeSlider"
      v-model="value"
      :min="min"
      :max="max"
      name="rangeslider"
      type="range"
      step="50"
    />

    <div class="fillRangesContainer">
      <div class="fillRanges" :style="`width: calc(${percent}% + 1px)`"></div>
    </div>
    <div class="outputContainer">
      <div
        class="output"
        name="output"
        for="rangeslider"
        :style="`left:${percent}%`"
      >
        <div class="valueContainer text-white">
          <span class="bold h2 text-white align-top"> {{ value }} </span>
          <span class="text-white"> FCFA </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderRange",
  props: {
    updateValue: Function,
  },
  data() {
    return {
      value: 1000,
      max: 5000,
      min: 1000,
      percent: (100 * this.value) / (this.max - this.min),
    };
  },
  mounted() {},
  watch: {
    value(newVale) {
      this.percent = (100 * (newVale - this.min)) / (this.max - this.min);
    },
  },
};
</script>

<style scoped>
.slideContaner {
  position: relative;
}
.fillRangesContainer {
  box-sizing: content-box;
  top: 55px;
  position: absolute;
  z-index: 2;
  width: calc(100% - 1.5em);
}
.fillRanges {
  height: 10px;
  box-sizing: content-box;
  border-radius: 0.7em 0 0 0.7em;
  background-color: var(--color3);
  position: absolute;
  z-index: 2;
}
.outputContainer {
  position: absolute;
  top: -1em;
  width: calc(100% - 1.5em);
  height: 5px;
  transform: translatex(-50%);
  left: calc(50%);
}
.output {
  transform: translateX(-50%);
  position: absolute;
}
.valueContainer {
  border-radius: 1rem;
  background-color: var(--color3);
  width: 9rem;
  padding: 0.4rem;
  height: 3rem;
}

.valueContainer:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--color3);
  border-bottom: 20px solid transparent;
  left: 50%;
  bottom: -30px;
  transform: translateX(-50%);
}
.rangeSlider {
  width: 100%;
  appearance: none;
  position: relative;
  z-index: 1;
}
.rangeSlider:active::-webkit-slider-thumb {
  appearance: none;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.rangeSlider:active::-moz-range-thumb {
  border: 0;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.rangeSlider:active::-ms-thumb {
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.rangeSlider:focus {
  outline: none;
}
.rangeSlider::-webkit-slider-thumb {
  appearance: none;
  border: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: var(--color3);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  margin-top: -0.5em;
}
.rangeSlider::-moz-range-thumb {
  border: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: var(--color3);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  margin-top: -0.5em;
}
.rangeSlider::-ms-thumb {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: var(--color3);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  margin-top: -0.5em;
}
.rangeSlider::-webkit-slider-runnable-track {
  height: 10px;
  box-sizing: content-box;
  border-radius: 0.7em;
  background-color: var(--white4);
}
.rangeSlider::-moz-range-track {
  height: 10px;
  box-sizing: content-box;
  border-radius: 0.7em;
  background-color: var(--white4);
}
.rangeSlider::-moz-focus-outer {
  border: 0;
}
.rangeSlider::-ms-track {
  border: 0;
  height: 10px;
  box-sizing: content-box;
  border-radius: 0.7em;
  background-color: var(--white4);
  color: transparent;
}
.rangeSlider::-ms-fill-lower,
.rangeSlider::-ms-fill-upper {
  background-color: transparent;
}
.rangeSlider::-ms-tooltip {
  display: none;
}

.rangeSlider::after {
  content: attr(slidertext);
  position: absolute;
  transform: translatex(-50%);
  left: calc(50% + 1.5em);
  top: 0.9em;
  color: white;
  width: max-content;
}

html {
  font-size: 32px;
  text-align: center;
}

h1 {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

.sliderContainer {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
