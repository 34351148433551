<template>
  <PictureSession componentClass="">
    <template v-slot:textPart>
      <div class="my-auto">
        <h1 class="bold color1">Des frais aussi claire que l'eau</h1>
        <p class="text-small">
          <span class="bold">Tu détestes les frais d'envoi ?</span> Nous aussi !
          Avec SmallKash, réclame une partie de tes frais
        </p>
        <router-link to="#" class="zoomHover">
          Économise sur tes frais dès maintenant &#8250;
        </router-link>
      </div>
    </template>
    <!-- <template v-slot:picturePart>
    </template> -->
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
export default defineComponent({
  name: "FeeSession",
  components: { PictureSession },
  data() {
    return {};
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
