<template>
  <PictureSession componentClass="">
    <template v-slot:textPart>
      <div class="my-auto">
        <h1 class="bold color1">
          Transfert vite, <br />
          Très vite
        </h1>
        <p class="text-small">
          10 secondes et 3 cliques. C'est tout ce qu'il te faut pour faire ton
          paiement
        </p>
        <a
          href="#"
          class="zoomHover "
        >
          Ne perd plus du temps pour tes transferts &#8250;
        </a>
      </div>
    </template>
    <template v-slot:picturePart>
      <div class="w-75 mx-auto">
        <lottie-player
          src="fast_pay_anim.json"
          background="transparent"
          speed="1.5"
          style="width: 100%"
          loop
          autoplay
        ></lottie-player>
      </div>
    </template>
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
export default defineComponent({
  name: "SpeedSession",
  components: { PictureSession },
  data() {
    return {};
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
