<template>
  <footer
    class="footer-1 space--xs text-center-xs pt-5 pb-3"
    style="margin-top: 0px"
  >
    <!-- <div class="container">
      <div class="row row-cols-2 row-cols-sm-4 row-cols-md-6">
        <div class="col">
          <h3 class="mt-1">
            <router-link
              to="/"
              class="navbar-brand logo zoomHover d-block w-100"
              ><img src="../../public/logo.png" /> SmallKash
            </router-link>
          </h3>
          <p>
            First mobile Top-up API made for developers. Enable your apps and
            websites to send mobile top-up worldwide.
          </p>
        </div>
        <div
          class="col"
          v-for="(footerSession, sessionKey) in footerSessions"
          :key="sessionKey"
        >
          <h3 class="mt-1">{{ footerSession.name }}</h3>
          <ul>
            <li
              class="sessionItem zoomHover"
              v-for="(sessionItem, itemKey) in footerSession.items"
              :key="itemKey"
            >
              <router-link class="" :to="sessionItem.path">
                {{ sessionItem.name }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="down-footer">
      <div class="col-12 text-center">
        <br /><span class="type--fine-print white"
          >Copyright © <span class="update-year">2021</span> Chipdeals Inc.
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footerSessions: [
        {
          name: "Use cases",
          items: [
            { name: "Send money", path: "#" },
            { name: "Ask money", path: "#" },
          ],
        },
        {
          name: "Resources",
          items: [
            { name: "Support", path: "#" },
            { name: "Developers", path: "#" },
            { name: "GitHub", path: "#" },
            { name: "Blog", path: "#" },
            { name: "Customer stories", path: "#" },
            { name: "Promotions", path: "#" },
          ],
        },
        {
          name: "Company",
          items: [
            { name: "About us", path: "#" },
            { name: "Contact us", path: "#" },
            { name: "Careers", path: "#" },
            { name: "Pricing", path: "#" },
            { name: "Media Kit", path: "#" },
          ],
        },
        {
          name: "Legal",
          items: [
            { name: "Terms of use", path: "#" },
            { name: "Privacy policy", path: "#" },
            { name: "Security information", path: "#" },
            { name: "Cookie policy", path: "#" },
            { name: "User agreement", path: "#" },
          ],
        },
        {
          name: "Social",
          items: [
            { name: "Twitter", path: "#" },
            { name: "Instagram", path: "#" },
            { name: "Facebook", path: "#" },
            { name: "GitHub", path: "#" },
          ],
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
}
a,
a:hover {
  color: var(--color1);
  text-decoration: none;
}
.logo,
.logo:hover {
  width: 100%;
  max-width: 25px;
  color: var(--color3) !important;
}
.sessionItem {
  font-weight: 100;
  font-size: 14px;
}
</style>
