<template>
  <PictureSession componentClass="" textToRight>
    <template v-slot:textPart>
      <div class="my-auto">
        <h1 class="bold color1">Contrôle la sécurité</h1>
        <p class="text-small">
          Nous n'avons accès ni à ton compte, ni à ton solde. Toute transaction
          est validée par ton code secret
        </p>
        <router-link to="#" class="zoomHover">
          Fais des transactions sécurisées &#8250;
        </router-link>
      </div>
    </template>
    <template v-slot:picturePart>
      <lottie-player
        src="/security_animation.json"
        background="transparent"
        speed="0.5"
        style="width: 100%"
        loop
        autoplay
      ></lottie-player>
    </template>
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
export default defineComponent({
  name: "SecuritySession",
  components: { PictureSession },
  data() {
    return {};
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
