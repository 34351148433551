<template>
  <section class="py-5">
    <div class="container text-center">
      <h1 class="bold color1">Prend ta liberté</h1>
      <br />
      <div class="text-start col-md-9 col-11 mx-auto">
        <table class="table table-bordered rounded-3">
          <tbody>
            <tr>
              <th class="d-none d-md-table-cell color1"></th>
              <th
                scope="col"
                class="color1"
                v-for="(solution, key) in fonctionnalitiesCompareData.solutions"
                :key="key"
              >
                {{ solution }}
              </th>
            </tr>
            <tr
              v-for="(
                comparedFonctionnality, comparedFonctionnalityKey
              ) in fonctionnalitiesCompareData.compare"
              :key="comparedFonctionnalityKey"
            >
              <th class="d-none d-md-table-cell color1">
                {{ comparedFonctionnality.fonctionnalityName }}
              </th>
              <td
                v-for="(
                  fonctionnality, fonctionnalityKey
                ) in comparedFonctionnality.comparing"
                :key="fonctionnalityKey"
                :scope="fonctionnalityKey === 0 ? 'row' : ''"
              >
                <img
                  :src="
                    require(`@/assets/pictures/${
                      fonctionnalityKey % 2 ? 'check-all' : 'close-thick'
                    }.svg`)
                  "
                  class="iconCompare"
                />
                {{ fonctionnality }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "FonctionnalityCompareSession",
  components: {},
  data() {
    return {
      fonctionnalitiesCompareData: {
        solutions: ["Momo / Moov Money", "SmallKash"],
        compare: [
          {
            fonctionnalityName: "Rapide",
            comparing: ["Lent", "Rapide"],
          },
          {
            fonctionnalityName: "Frais d'envoi",
            comparing: ["Frais fixe", "Economique"],
          },
        ],
        caculationValue: 0,
      },
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.partnerPicContainer {
  height: 100px;
}
.partnerPic {
  max-height: 100%;
  width: auto !important;
  max-width: 100%;
}
.iconCompare {
  width: 20px;
}
</style>
