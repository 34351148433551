<template>
  <div id="animationNumber" class="d-inline-block"></div>
</template>

<script>
export default {
  name: "NumberAnimated",
  props: {
    number: Number,
    duration: Number,
  },
  data() {
    return {
      value: 1000,
      max: 5000,
      min: 1000,
      percent: (100 * this.value) / (this.max - this.min),
    };
  },
  mounted() {
    const obj = document.querySelector("#animationNumber");
    this.animateValue(obj, 0, this.number, this.duration);
  },
  methods: {
    animateValue: function (obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<style scoped>
#animationNumber {
  animation: zoom-in-zoom-out 2s ease-out 2;
  animation-delay: 1.5s;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.8, 2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
