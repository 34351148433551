<template>
  <PictureSession componentClass="" textToRight>
    <template v-slot:textPart>
      <div class="my-auto">
        <h1 class="bold color1">Découvre ce que tu fais avec ton argent</h1>
        <a
          href="#"
          class="zoomHover "
        >
          Ne perds plus le compte de ton argent &#8250;
        </a>
      </div>
    </template>
    <!-- <template v-slot:picturePart>
    </template> -->
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
export default defineComponent({
  name: "HistorySession",
  components: { PictureSession },
  data() {
    return {};
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
