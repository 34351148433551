<template>
  <div class="ticontainer">
    <div class="tiblock">
      <div
        class="tidot"
        :style="{
          'animation-duration': `${animationDurationInMs}ms`,
          'animation-delay': `${(animationDurationInMs / 10) * 2}ms`,
        }"
      ></div>
      <div
        class="tidot"
        :style="{
          'animation-duration': `${animationDurationInMs}ms`,
          'animation-delay': `${(animationDurationInMs / 10) * 3}ms`,
        }"
      ></div>
      <div
        class="tidot"
        :style="{
          'animation-duration': `${animationDurationInMs}ms`,
          'animation-delay': `${(animationDurationInMs / 10) * 4}ms`,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextTypingAnim",
  props: {
    animationDurationInMs: Number,
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tiblock {
  align-items: center;
  display: flex;
  height: 17px;
}

.ticontainer .tidot {
  background-color: #90949c;
}

.tidot {
  animation: mercuryTypingAnimation infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
}

.tidot:nth-child(1) {
  animation-delay: 200ms;
}
.tidot:nth-child(2) {
  animation-delay: 300ms;
}
.tidot:nth-child(3) {
  animation-delay: 400ms;
}
</style>
