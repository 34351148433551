const axios = require("axios");
const { Commons } = require("./commons");

class RequestUtils {
  static async request(url, method="get", data=null) {
    const requestConfig = {
      url,
      method
    };
    Commons.isEmpty(data) ? null : (requestConfig.data = data);
    const response = await axios.request(requestConfig).catch(error => {
      console.log(`Failed caused by access to : ${url} with error :  ${error}`);
      console.log(error.message);

      return error.response || error;
    });

    return response.data
      ? { ...response.data, responseInfos: { statusCode: response.status } }
      : response;
  }
}

exports.RequestUtils = RequestUtils;
