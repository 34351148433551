<template>
  <section class="py-5">
    <div class="container text-center">
      <h1 class="bold color1">
        Combien tu perds en utilisant directement MoMo
      </h1>
      <br />
      <div class="col-md-9 col-11 mx-auto cardSession p-5">
        <span class="color1">Montant à envoyer</span>
        <br />
        <br />
        <div>
          <SliderRange :updateValue="updatePriceValue" />
        </div>
        <div class="row text-start pb-3">
          <div
            class="form-check col-md-4 zoomHover"
            v-for="(calculPeriod, index) in calculPeriods"
            :key="index"
          >
            <input
              class="form-check-input"
              type="radio"
              v-model="period"
              :value="calculPeriod.dayInterval"
              :id="'periodeCalcul' + index"
            />
            <label class="form-check-label" :for="'periodeCalcul' + index">
              {{ calculPeriod.name }}
            </label>
          </div>
        </div>
        <h5 class="bold text-danger mb-2">
          Tu perds
          <span
            class="spinner-border spinner-border-sm text-danger"
            role="status"
            v-if="loadingLost"
          >
            <span class="visually-hidden">Loading...</span>
          </span>
          <span v-else class="bold text-danger">{{ lost }}f </span>
        </h5>
        <div class="col-sm-8 col-md-6 col-ld-5 mx-auto">
          <a
            class="w-100 zoomHover customedBtn ctaBtn p-2 "
            :href="
              'https://play.google.com/store/apps/details?id=me.chipdeals.chipdeals&referrer=utm_source%3DSmallKash landing page%26utm_medium%3DEconomy calcul sesion%26utm_content%3DRéclamer mes ' +
              lost +
              'f'
            "
          >
            <div
              class="spinner-border text-light"
              role="status"
              v-if="loadingLost"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <h4 v-else class="text-white bold">Réclamer mes {{ lost }}f 😡</h4>
          </a>
        </div>
        <div class="text-muted smallText py-1">
          {{ totalCalculationPeriod.name }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import SliderRange from "@/components/SliderRange.vue";

export default defineComponent({
  name: "EconomyCalculSession",
  components: {
    SliderRange,
  },
  data() {
    return {
      priceValue: 1000,
      calculPeriods: [
        {
          name: "1 fois / Jour",
          dayInterval: 1,
        },
        {
          name: "3 fois / semaine",
          dayInterval: 3,
        },
        {
          name: "4 fois / mois",
          dayInterval: 7,
        },
      ],
      totalCalculationPeriod: {
        name: "*Coût calculé sur une année",
        totalDay: 365,
      },
      period: 1,
      economy: 0,
      loss: 0,
      loadingEconomy: false,
      loadingLost: false,
    };
  },
  mounted() {
    this.computeLost();
  },
  methods: {
    updatePriceValue: function (newValue) {
      this.priceValue = newValue;
    },
    computeEconomy: function () {
      setTimeout(() => {
        this.loadingEconomy = false;
      }, 1000);
      this.loadingEconomy = true;
      let percent = 2 / 100;
      if (this.priceValue > 4500) percent = 1.7 / 100;
      const singleEconomy = Math.ceil(100 - this.priceValue * percent);
      const nbSingleEconomyOnPeriod = Math.floor(
        this.totalCalculationPeriod.totalDay / this.period
      );
      const tolalEconomy = singleEconomy * nbSingleEconomyOnPeriod;
      this.economy = tolalEconomy;
    },
    computeLost: function () {
      setTimeout(() => {
        this.loadingLost = false;
      }, 1000);
      this.loadingLost = true;
      const singleLost = 100;
      const nbSingleEconomyOnPeriod = Math.floor(
        this.totalCalculationPeriod.totalDay / this.period
      );
      const tolalLost = singleLost * nbSingleEconomyOnPeriod;
      this.lost = tolalLost;
    },
  },
  watch: {
    priceValue: function () {
      this.computeLost();
    },
    period: function () {
      this.computeLost();
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.smallText {
  font-size: 0.7em;
}
</style>
