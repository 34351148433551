<template>
  <div class="w-100 wellcomPictureContainer">
    <div
      class="w-75 mx-auto text-start wellcomPicture"
      :class="{ rotate: containerInRotation }"
    >
      <div class="partsContainer mx-auto floatAnim">
        <div class="picturePhone">
          <img src="@/assets/pictures/messagePhone.svg" alt="" />
        </div>
        <div class="messagesContainer col-sm-9 col-md-11 col-lg-9 mx-auto">
          <div
            class="row"
            v-for="(message, index) in messagesDisplayed"
            :key="index"
            :v-bind="
              (showSenderName =
                message.senderNo === 1 ||
                message.senderNo !== messages[index - 1].senderNo)
            "
          >
            <div
              :class="{ 'mt-1': showSenderName && message.senderNo !== 1 }"
            ></div>
            <div
              class="d-flex messageRow"
              :class="[
                message.senderNo === 1
                  ? 'justify-content-start messageLeft'
                  : 'justify-content-end messageRight',
                showSenderName ? 'largHeight' : 'lowHeight',
              ]"
            >
              <div class="sender mx-2" v-show="showSenderName">
                {{ message.senderName }}
              </div>
              <div
                class="message mt-2"
                :class="[
                  { 'mt-3': showSenderName },
                  { messageWithPicture: !!message.picture },
                ]"
              >
                <div class="messagePicture" v-if="message.picture">
                  <img :src="require(`@/assets/pictures/${message.picture}`)" />
                </div>
                <div class="massageCaracteres">
                  <div class="messageTitle bold color1" v-show="message.title">
                    {{ message.title }}
                  </div>
                  <div class="messageText">
                    {{ message.text }}
                  </div>
                </div>
              </div>
              <div
                class="typingBubble mt-2"
                :class="{ 'mt-3': showSenderName }"
              >
                <TextTypingAnim :animationDurationInMs="1000" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="sendingMoneySimulation text-center pt-4"> -->
        <div
          class="sendingMoneySimulation text-center pt-4"
          v-show="makePayingSimulation"
        >
          <div class="pt-2">
            <span class="h6 text-white">Payer la commande</span>
            <div class="simulationBody pt-1 px-4 w-100">
              <div class="px-4 h-100">
                <div class="recipientInfo text-center">
                  <div class="recipientPictureContainer mx-auto h1 display-1">
                    <img src="@/assets/pictures/prodctPicture.jpg" alt="" />
                  </div>
                  <span class="bold"> Marchand </span>
                  <p class="description">Coffret Eclat</p>
                  <div class="recipientAmountContainer w-100 txt-center">
                    <div class="priceAmount pb-2">
                      <div class="align-top d-inline-block largText bold">
                        15000
                      </div>
                      <div class="align-top d-inline-block">FCFA</div>
                    </div>
                  </div>
                  <div class="accept text-end">Glisser pour payer</div>
                  <div class="reject w-100 txt-center">Rejeter</div>
                </div>
              </div>
            </div>
            <div class="simulationModalContainer">
              <div class="simulationModalPending pt-3">
                <span class="h6">Confirmation en cour</span>
                <div class="loading-dual-ring"></div>
              </div>
              <div class="simulationModalSuccess pt-3">
                <span class="h6">Transfert effectué avec succès</span>
                <div class="successModalIcon">
                  <svg class="checkedBox" />
                </div>
              </div>
            </div>
            <div class="simulationUssdModal">
              Voulez-vous envoyer 15000 FCFA au 90656535 ? Entrez votre code
              secret pour confirmer <br />
              <br />
              <div class="simulationUssdModalInput">
                <div class="d-flex">
                  <div>
                    <div class="typing">12345</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="picturePhoneHead">
          <img src="@/assets/pictures/messagePhoneHead.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import TextTypingAnim from "@/components/TextTypingAnim.vue";
export default defineComponent({
  name: "WellcomeSessionPicture",
  components: { TextTypingAnim },
  data() {
    return {
      messages: [
        {
          senderNo: 1,
          senderName: "Aurelie",
          text: "Bonjour, la crème éclat est-elle à nouveau disponible ?",
        },
        {
          senderNo: 2,
          senderName: "Marchand",
          text: "Bonjour, Oui",
        },
        {
          senderNo: 2,
          senderName: "Marchand",
          text: "Elle fait partie du nouveau coffret que nous venons de commercialiser",
        },
        {
          senderNo: 2,
          senderName: "Marchand",
          picture: "prodctPicture.jpg",
          title: "Coffret Eclat",
          text: "http://pay.chipdeals.me/123456789123456789",
        },
      ],
      messagesDisplayed: [],
      makePayingSimulation: false,
      containerInRotation: false,
    };
  },
  mounted() {
    this.displayNewMessage();
    setInterval(() => {
      if (this.makePayingSimulation) return;
      this.displayNewMessage();
    }, 1500);
  },
  methods: {
    displayNewMessage: function () {
      if (this.messagesDisplayed.length === this.messages.length) {
        this.simulateLinkClicked();
        return;
      }
      this.messagesDisplayed.push(this.messages[this.messagesDisplayed.length]);
    },
    simulateLinkClicked: function () {
      setTimeout(() => {
        this.makePayingSimulation = true;
      }, 500);
      setTimeout(() => {
        this.finishSimulation();
      }, 10000);
    },
    finishSimulation() {
      this.containerInRotation = true;
      setTimeout(() => {
        this.makePayingSimulation = false;
        this.messagesDisplayed = [];
      }, 250);
      setTimeout(() => {
        this.containerInRotation = false;
      }, 500);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wellcomPictureContainer {
  height: 500px;
  position: relative;
  perspective-origin: center;
  perspective: 800px;
  transform: scale(0.8);
}

.wellcomPicture {
  height: 500px;
  position: relative;
  transform: rotateX(0deg) rotateY(-15deg) rotateZ(8deg);
  backface-visibility: hidden;
}
@media screen and (max-width: 766px) {
  .wellcomPicture {
    transform: rotateX(0deg) rotateY(-2deg) rotateZ(0deg);
  }
}

.wellcomPicture,
.wellcomPicture * {
  user-select: none;
}
.wellcomPicture.rotate {
  animation: rotate 500ms infinite ease-in-out;
}
.partsContainer {
  position: relative;
  width: 250px;
  transition-duration: 400ms;
}
.partsContainer > * {
  position: absolute;
}
.picturePhone {
  width: 100%;
}
.picturePhoneHead {
  width: 100%;
  z-index: 50;
}
.messagesContainer {
  width: 225px;
  top: 89px;
  /* border: solid 1px; */
  height: 348px;
  padding: 0 8px;
  left: 12px;
}

.typingBubble {
  position: absolute;
  animation: typingBubbleAnim 1s;
  transform: scale(0);
  background-color: var(--white3);
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: var(--white4) 0px 10px 40px -10px;
}
.message {
  animation: messageAnimation 2s;
  background-color: var(--white1);
  border-radius: 15px;
  box-shadow: var(--white4) -10px 10px 40px -10px;
  position: relative;
  font-size: 11px;
  word-break: break-word;
  max-width: 93%;
}
.message.messageWithPicture {
  width: 60%;
  max-width: 170px;
}
.message .massageCaracteres {
  padding: 5px 10px;
}
.message .messagePicture {
  width: 100%;
  margin-bottom: 1px;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  max-height: 103px;
}
.message .messageTitle {
  font-size: 15px;
  color: var(--color1);
}
.message::before {
  content: "";
  mask: url("../assets/pictures/messageBottom.svg") no-repeat center;
  background-color: var(--white1);
  mask-size: 100%;
  position: absolute;
  width: 20px;
  height: 21px;
  left: -7px;
  bottom: -5px;
}

.messageRight .message * {
  color: white !important;
}
.messageRight .message {
  background-color: #44cc88;
  box-shadow: var(--white4) 10px 10px 40px -10px;
}
.messageRight .message::before {
  background-color: #44cc88;
  width: 20px;
  height: 21px;
  right: -7px;
  bottom: -5px;
  left: inherit;
  transform: rotateY(180deg);
}
.sender {
  position: absolute;
  font-size: 10px;
}
.sendingMoneySimulation {
  width: calc(100% - 24px);
  height: 468.6px;
  /* border: solid 1px; */
  left: 12px;
  top: 11.4px;
  border-radius: 29px;
  background-color: var(--color3);
  overflow: hidden;
  animation: zoomIn 500ms ease-in;
}

.sendingMoneySimulation > * {
  animation: notVisible 500ms ease-in;
}

.simulationBody {
  position: absolute;
  bottom: 0px;
  height: 85%;
  border-radius: 3em 3em 0 0;
  background-color: var(--white2) !important;
  overflow: auto;
  animation: slideFromBottom 1s 500ms ease-in;
  z-index: 5;
}
.simulationBody > * {
  animation: showAtEnd 1s 500ms ease-in !important;
}

.recipientPictureContainer {
  width: 8rem;
  height: 6rem;
  border-radius: 1.5rem;
}

.recipientPictureContainer > img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.recipientPicture {
  font-size: 3rem;
}

.recipientAmountContainer {
  position: absolute;
  bottom: 30%;
  left: 0px;
}

.accept {
  background-color: var(--color3);
  color: white !important;
  position: absolute;
  bottom: 16%;
  left: 5%;
  width: 90%;
  height: 40px;
  border-radius: 10px;
  padding-top: 8px;
  padding-right: 20px;
  overflow: hidden;
  animation: slideToUnlookSuccess 500ms 2500ms ease-in;
}

.accept:before {
  content: "";
  background-color: white;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  margin: 4px;
  animation: slideToUnlookButtonSliding 1s 1500ms ease-in,
    slideToUnlookButtonAparenceChanged 500ms 2500ms;
}

.accept:after {
  content: "";
  background-color: var(--color3);
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  margin: 4px;
  mask: url("../assets/pictures/fast-forward.svg") no-repeat center;
  background-size: contain;
  mask-size: 70% 70%;
  animation: slideToUnlookButtonIconSliding 1s 1500ms ease-in,
    slideToUnlookButtonIconAparenceChanged 500ms 2500ms;
}

.reject {
  position: absolute;
  bottom: 5%;
  left: 0px;
}

.largText {
  font-size: 3em;
  line-height: 0.95em;
}
.simulationModalContainer {
  width: 100%;
  position: absolute;
  background-color: rgba(180, 180, 180, 0.5);
  z-index: 10;
  height: 100%;
  top: 0;
  opacity: 0;
  animation: visible 500ms 3000ms ease-in, visible 5s 3500ms infinite;
}

.simulationModalPending {
  position: absolute;
  left: 15%;
  /* box-shadow: var(--white3) -4px 4px 20px 2px; */
  background-color: var(--white2);
  border-radius: 0.5rem;
  margin-top: 25px;
  width: 70%;
  top: 30%;
  height: 30%;
  animation: zoomIn 500ms 3000ms ease-in, notVisible 5s 6500ms infinite;
}

.loading-dual-ring {
  width: 40%;
  height: 40%;
  left: 30%;
  position: relative;
}
.loading-dual-ring:after {
  content: " ";
  display: block;
  width: 54px;
  height: 54px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--color3);
  border-color: var(--color3) transparent var(--color3) transparent;
  animation: loading-dual-ring 1.2s linear infinite;
}

.simulationModalSuccess {
  position: absolute;
  left: 18%;
  /* box-shadow: var(--white3) -4px 4px 20px 2px; */
  background-color: var(--white2);
  border-radius: 0.5rem;
  margin-top: 25px;
  width: 70%;
  top: 30%;
  height: 30%;
  animation: notVisible 6500ms, zoomIn 500ms 6500ms ease-in;
}

.successModalIcon {
  width: 50%;
  height: 50%;
  left: 25%;
  position: relative;
}

.checkedBox {
  background-color: green;
  mask: url("../assets/pictures/check-all.svg") no-repeat center;
  background-size: contain;
  mask-size: 100% 100%;
  width: 80%;
  height: 80%;
}

.simulationUssdModal {
  position: absolute;
  left: 5%;
  background-color: white;
  margin-top: 25px;
  padding: 5px;
  padding-top: 5px;
  width: 90%;
  top: 31%;
  height: 25%;
  /* box-shadow: 0 0 5px 0px black; */
  font-size: 11px;
  opacity: 0;
  animation: zoomIn 500ms 4000ms ease-in, visible 2s 4000ms;
  z-index: 15;
}

.simulationUssdModalInput {
  border: solid 1px;
  border-color: transparent transparent var(--color1) transparent;
  margin: 10px;
  margin-top: 5px;
}

.typing {
  animation: notVisible 1s 4000ms, typingAnim 500ms 5000ms steps(30, end),
    blink-caret 0.5s step-end infinite;
  overflow: hidden;
  border-right: 0.15em solid black;
  white-space: nowrap;
}

@keyframes rotate {
  to {
    transform: rotateX(0deg) rotateY(345deg) rotateZ(8deg);
  }
}
@media screen and (max-width: 766px) {
  @keyframes rotate {
    to {
      transform: rotateX(0deg) rotateY(340deg) rotateZ(0deg);
    }
  }
}

@keyframes typingBubbleAnim {
  0% {
    transform: scale(0);
  }
  17% {
    transform: scale(1);
  }
  83% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes messageAnimation {
  0% {
    opacity: 0;
    transform: scaleY(0);
    display: none;
  }
  50% {
    opacity: 0;
    transform: scaleY(0);
  }
  55% {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes notVisible {
  from,
  to {
    opacity: 0;
  }
}

@keyframes visible {
  from,
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes slideFromBottom {
  from {
    height: 0%;
    overflow: hidden;
  }
  70% {
    height: 85%;
    overflow: hidden;
  }
  100% {
    overflow: hidden;
  }
}
@keyframes showAtEnd {
  from {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
}

@keyframes typingAnim {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

@keyframes slideToUnlookButtonSliding {
  from {
    left: 0px;
  }
  100% {
    left: calc(100% - 40px);
  }
}

@keyframes slideToUnlookButtonIconSliding {
  from {
    left: 0px;
  }
  100% {
    left: calc(100% - 40px);
  }
}

@keyframes slideToUnlookButtonAparenceChanged {
  from,
  to {
    background-color: var(--color3);
  }
}

@keyframes slideToUnlookButtonIconAparenceChanged {
  from,
  to {
    background-color: white;
    mask: url("../assets/pictures/check-_1_.svg") no-repeat center;
    mask-size: 70% 70%;
  }
}

@keyframes slideToUnlookSuccess {
  from {
    padding-top: 40px;
  }
  40%,
  to {
    padding-top: 40px;
    width: 40px;
    left: calc(50% - 20px);
  }
}

@keyframes loading-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
