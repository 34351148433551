<template>
  <PictureSession
    componentClass=""
    picturAndTextContainerClass="bg-white3 px-1 py-5 m-3 cardSession"
    pictureClass=" d-none d-md-inline-flex"
  >
    <template v-slot:textPart>
      <div class="my-auto">
        <h2 class="bold color1">Supprime tes frais pour de bon</h2>
        <div class="text-small">
          <span class="text-small">
            <span class="bold"> 60+ utilisateurs </span> ont déja
            économisé</span
          >
          <br />
          <div class="text-center text-md-start py-3">
            <div class="valueTransaction color1" v-if="showAnimatedValue">
              <span class="bold h1 color1 align-top mx-3">
                <NumberAnimated
                  :number="valueSaved"
                  :duration="valueAnimationDuration"
                />
              </span>
              <span class=""> FCFA </span>
            </div>
            <div v-else class="valueTransaction"></div>
          </div>
        </div>
        <a
          href="#"
          class="customedBtn rounded-5 ctaBtn whiteBtn zoomHover "
        >
          Télécharge SmallKash</a
        >
      </div>
    </template>
    <!-- <template v-slot:picturePart>
    </template> -->
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
import NumberAnimated from "@/components/NumberAnimated.vue";
const { Utils } = require("@/assets/js/utils");

export default defineComponent({
  name: "DownloadCardSession",
  components: { PictureSession, NumberAnimated },
  data() {
    return {
      valueSaved: 5000,
      valueAnimationDuration: 2000,
      showAnimatedValue: false,
    };
  },
  async mounted() {
    this.valueSaved = await Utils.getTotalUserEconomyAmount();
    this.makeSavedValueAnimation();
  },
  methods: {
    makeSavedValueAnimation: function () {
      this.showAnimatedValue = true;
      setTimeout(() => {
        this.showAnimatedValue = false;
        setTimeout(() => {
          this.makeSavedValueAnimation();
        }, 500);
      }, 8000);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cardSession {
  box-shadow: var(--white4) -6px 14px 20px 1px;
  border-radius: 0.7em;
}
.valueTransaction {
  height: 3em;
}
</style>
