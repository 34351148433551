<template>
  <PictureSession componentClass="" textToRight>
    <template v-slot:textPart>
      <div class="my-auto">
        <h1 class="bold color1">Tes frais d'envoi te sont rendus</h1>
        <p class="text-small">
          <!-- Avec SmallKash, <br class="d-md-none" /> -->
          Smallkash fait recracher une partie des tes frais aux réseaux.
        </p>
        <a
          href="#"
          class="zoomHover "
        >
          Réclame une partie des tes frais &#8250;
        </a>
      </div>
    </template>
    <template v-slot:picturePart>
      <lottie-player
        src="/pig.json"
        background="transparent"
        speed="1"
        style="width: 100%"
        loop
        autoplay
      ></lottie-player>
    </template>
  </PictureSession>
</template>

<script>
import { defineComponent } from "vue";
import PictureSession from "@/components/PictureSession.vue";
export default defineComponent({
  name: "EconomySession",
  components: { PictureSession },
  data() {
    return {};
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pictureApplatis {
  border: 0.2px dashed var(--white4);
  border-radius: 1em;
  overflow: hidden;
}
</style>
