<template>
  <div class="home">
    <WellcomeSession />
    <session v-if="false">
      <div class="container text-center bg-white5 py-5 rounded">
        <h1 class="bold color1">
          Tu utilises MoMoPay
          <br class="d-md-none" />
          mais tes clients ont du mal avec
        </h1>
        <div class="h5 col-10 mx-auto mt-4">
          <span class="">
            Facilite le paiement à tes clients. Ne livre plus des produits avec
            l'incertitude de te faire payer
          </span>
        </div>
      </div>
    </session>
    <EconomyCalculSession v-if="false" />
    <EconomySession v-if="false" />
    <SpeedSession v-if="false" />
    <SecuritySession v-if="false" />
    <FeeSession v-if="false" />
    <HistorySession v-if="false" />
    <OperatorsSession v-if="false" />
    <FonctionnalityCompareSession v-if="false" />
    <DownloadCardSession v-if="false" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import WellcomeSession from "@/components/WellcomeSession.vue";
import EconomySession from "@/components/EconomySession.vue";
import SpeedSession from "@/components/SpeedSession.vue";
import SecuritySession from "@/components/SecuritySession.vue";
import FeeSession from "@/components/FeeSession.vue";
import HistorySession from "@/components/HistorySession.vue";
import DownloadCardSession from "@/components/DownloadCardSession.vue";
import OperatorsSession from "@/components/OperatorsSession.vue";
import EconomyCalculSession from "@/components/EconomyCalculSession.vue";
import FonctionnalityCompareSession from "@/components/FonctionnalityCompareSession.vue";

export default defineComponent({
  name: "Home",
  components: {
    WellcomeSession,
    EconomySession,
    SpeedSession,
    SecuritySession,
    FeeSession,
    HistorySession,
    DownloadCardSession,
    OperatorsSession,
    EconomyCalculSession,
    FonctionnalityCompareSession,
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.showLaters = true;
    }, 2000);
  },
  methods: {},
});
</script>

<style scoped></style>
