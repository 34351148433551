const mixpanel = require("mixpanel-browser");
const { Commons } = require("./commons");

class AnalyticsUtils {
  static initAnalytics(isFirsVisit) {
    window.smallKash.mixpanel = mixpanel;
    window.smallKash.mixpanel.init(getMixpanelKey(), {
      debug: !Commons.isLiveEnv()
    });
    window.smallKash.mixpanel.track("website visit");
    if (isFirsVisit) {
      window.smallKash.mixpanel.track("website first visit");
    }
  }
  static catchEvent(eventName, eventData) {
    window.smallKash.mixpanel.track(eventName, eventData);
  }
}

function getMixpanelKey() {
  if (Commons.isLiveEnv()) {
    return liveMixpanelKey;
  }
  return devMixpanelKey;
}

const liveMixpanelKey = "ee97fde54f5470cfb53d4d053e76649f";
const devMixpanelKey = "c98e96dd4e831c2b6211061eaa008b9e";

exports.AnalyticsUtils = AnalyticsUtils;
