  import { createApp } from "vue";
  import App from "./App.vue";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "bootstrap";
  import router from "./router";
  import VueHighlightJS from "vue3-highlightjs";
  import "highlight.js/styles/solarized-dark.css";
  import "animate.css"
  import VueCookies from 'vue3-cookies'
  import {
    create,
    NButton,
    NDropdown,
    NMessageProvider,
    NSpace,
    NCode,NTooltip
  } from "naive-ui";
  import i18n from "./i18n";


  const naive = create({
    components: [NButton, NDropdown, NMessageProvider, NSpace, NCode,NTooltip ]
  });

  const app = createApp(App);
  app.use(router);
  app.use(naive);
  app.use(i18n);
  app.use(VueHighlightJS);
  app.use(VueCookies);
  app.mount("#app");
